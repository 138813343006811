import * as React from 'react';
import cn from 'classnames';
import css from './TwoColumnHero.scss';
import Grid from 'styleguide/components/Grid/Grid';
import RushActionButton from 'bundles/App/pages/service/RushActionButton/RushActionButton';
import placeholderImg from 'assets/images/rush_critical/rush-critical.jpg';
import { H1 } from 'styleguide/components/Heading';

const TwoColumnHero = () => (
  <Grid.Container className={cn(css.container)}>
    <Grid fullWidth>
      <Grid.Row className={css.row}>
        <Grid.Col sm={12} lg={6} className={cn(css.column)}>
          <div className={css.largeHeroWrapper}>
            <H1 className={css.title}>Get Your Prints Within 24 Hours.</H1>
          </div>
          <div className={css.smallHeroWrapper}>
            <p className={css.description}>
              Same day printing, overnight printing, and next day printing services.
            </p>
          </div>
          <RushActionButton />
        </Grid.Col>
        <Grid.Col
          sm={12}
          lg={6}
          className={cn(css.imageColumn, css.placeHolder)}
          style={{
            backgroundImage: `url(${placeholderImg})`,
          }}
        />
      </Grid.Row>
    </Grid>
  </Grid.Container>
);

export default TwoColumnHero;
